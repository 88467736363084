import {
    Card,
} from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
// import { DeckQueryContext } from "../context/DeckQueryContext";
import { AuthContext } from "../context/AuthContext";
import AccountDecks from "./AccountDecks";
import AccountFavoriteDecks from "./AccountFavoriteDecks";
// import ImageUpload from "./ImageUpload";


function FBAccountPage() {
    const {
        account,
        // users,
        // getAccountData,
    } = useContext(AuthContext)

    // const account = {
    //     "userData": {
    //         "uid": "DGVcOcdyaYPod2t5Zvs5ROwmWaY2",
    //         "email": "nantahkl@gmail.com",
    //         "emailVerified": true,
    //         "displayName": "Kornebari Nantah",
    //         "isAnonymous": false,
    //         "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocLOW4_R7XSuovw5HuRkxBhfubbOlrURfCWNTorZQDOZ2cbWLA=s96-c",
    //         "providerData": [
    //             {
    //                 "providerId": "google.com",
    //                 "uid": "113387828856354681577",
    //                 "displayName": "Kornebari Nantah",
    //                 "email": "nantahkl@gmail.com",
    //                 "phoneNumber": null,
    //                 "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocLOW4_R7XSuovw5HuRkxBhfubbOlrURfCWNTorZQDOZ2cbWLA=s96-c"
    //             }
    //         ],
    //         "stsTokenManager": {
    //             "refreshToken": "AMf-vBwaOY8pFxIET_kD6lfovwZzf1Mu4CENtGv-FBOcyI6Pv4rMKgubvkDy-1nEKvZH0dHvA7F2Kv8MDJO2cT0-Qps-Jxw_hyB87glfh50DZD7hiFHdZ-c-UweZpPlWJTBBgO8E9Y93pzLNLg0JYZ8E_fGxIT72IWdUyqsnAXqztzvDsjZlDCPLRoXrDIqc9wm41JmHQcGKJpttrlg9xpu5CiIF_2Qa6i7awtRL-rC9KY0ulB-yRlcCir0wYog_KEIC_i5X6KmFclUAij3-7MeLMYTnXzJBnNUMzlD0iCNNu-dZHX6qtsixAX19b-G1YUcjmGusRa38VWCpRUCVrvOBvEgfLBRRoaQdw4cO2kVoogb2SqpFR_mwxQE-eDhcmP0j-hIu_ZLaKUhQqO-jEwI62j69f-3h8fmA9srW_6jneb3jMfe92xs",
    //             "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjFkYmUwNmI1ZDdjMmE3YzA0NDU2MzA2MWZmMGZlYTM3NzQwYjg2YmMiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiS29ybmViYXJpIE5hbnRhaCIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQ2c4b2NMT1c0X1I3WFN1b3Z3NUh1Umt4QmhmdWJiT2xyVVJmQ1dOVG9yWlFET1oyY2JXTEE9czk2LWMiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY2FyZGJhc2UtMWIxYTciLCJhdWQiOiJjYXJkYmFzZS0xYjFhNyIsImF1dGhfdGltZSI6MTcyMjg3NzEzMCwidXNlcl9pZCI6IkRHVmNPY2R5YVlQb2QydDVadnM1Uk93bVdhWTIiLCJzdWIiOiJER1ZjT2NkeWFZUG9kMnQ1WnZzNVJPd21XYVkyIiwiaWF0IjoxNzIyODc3MTMwLCJleHAiOjE3MjI4ODA3MzAsImVtYWlsIjoibmFudGFoa2xAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMTMzODc4Mjg4NTYzNTQ2ODE1NzciXSwiZW1haWwiOlsibmFudGFoa2xAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiZ29vZ2xlLmNvbSJ9fQ.v2z43kN4PSqQaNSF85ZC_tXA8OOvtW8Py8cURISPyB-W-O9UUIYp0UYNbSzGGu2QMIvN2a5-TgmlLCCks2m0Qlcjj32YffZqjikpqo-OI5GBiD_wAIniukGzFv8rh4scTv4f-OcSbvspJflmHK4amxQ2GGC2-hEABuuHBD2zS_RoZcek1WOOxjDqvDIUle0T5TKLie343jYN4q5va0ixezF36RkGMYOoa7_I41nCrn5A9j8C2bclkQK0Uge1TXi21KGFQSzdONe0imFh9XVwuxAnPZ1wsazMRmVppHy1uVlXhDtPWviXliCOlqdOFjLOomnxLLViMwpif-AIGsy7_g",
    //             "expirationTime": 1722880730047
    //         },
    //         "createdAt": "1714178117633",
    //         "lastLoginAt": "1722401164688",
    //         "apiKey": "AIzaSyDgkedttmEsg5xpChub6FDbr5AVhs9NEJM",
    //         "appName": "[DEFAULT]"
    //     },
    //     "username": "TeamPlayMaker",
    //     "collection": [],
    //     "wishlist": [],
    //     "decks": [],
    //     "favorited_decks": [
    //         "64fc6447eff176ba42293519",
    //         "6567d2ce6b97532b76bf65b7",
    //         "65712e5b0bb745cd65277818",
    //         "6571e9e30bb745cd65277829",
    //         "6551865dd449cdc3d0b3f93c",
    //         "65ec8e93f28d4a1e36ea0ad5",
    //         "658747711df6bfa47c978bbd",
    //         "js3OEaf4GczSY4xepsBbK5wp",
    //         "6563553fa88a701900626aee",
    //         "B0xE2Cw5GZA4nPf1JfL2s26o",
    //         "9pSUGncCWNJVkJtSrCLirJ5E",
    //         "UB99lNIJkpUO8GinVngNpqLN",
    //         "5ZYma87uvGEYz8px4qwpMzmC"
    //     ],
    //     "roles": [
    //         "admin"
    //     ],
    //     "created_on": {
    //         "seconds": 1693096516,
    //         "nanoseconds": 927000000
    //     },
    //     "id": "64f69854879b29f8b4dd51cd"
    // }

    const [option, setOption] = useState("profile")
    const navigate = useNavigate()

        useEffect(() => {
        window.scroll(0, 0);
        document.body.style.overflow = 'auto';
        // getAccountData();
        document.title = "Account Info - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    }, []);

    const handleOption = (item) => {
        setOption(item);
    };

    console.log(account)

    if (!account) {
        setTimeout(function() {
            window.location.href = `${process.env.PUBLIC_URL}/`
        }, 3000);
    }

    return (
        <div>
            {account?
                <div className="white-space">
                    <div className="between-space">
                        <div className="account-info-container flex-left-media-center">
                            <div>
                                <h1 className="left-h1 margin-top-none">Account Page</h1>
                                <h4 className="left">Welcome back, {account.username}!</h4>
                                <span className="none">
                                    {/* <div className={option === "profile"? "bigStaunch3 selected2 pointer account-option-item" : "bigStaunch3 pointer account-option-item half"}
                                        onClick={() => handleOption("profile")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>Profile</h4>
                                    </div> */}
                                    {/* <div className={option === "security"? "bigPower3 selected2 pointer account-option-item" : "bigPower3 pointer account-option-item half"}
                                        onClick={() => handleOption("security")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>Security</h4>
                                    </div> */}
                                    <div className={option === "myDecks"? "bigUnity3 selected2 pointer account-option-item" : "bigUnity3 pointer account-option-item half"}
                                        onClick={() => handleOption("myDecks")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>My Uploaded Decks</h4>
                                    </div>
                                    <div className={option === "favoriteDecks"? "bigCanny3 selected2 pointer account-option-item" : "bigCanny3 pointer account-option-item half"}
                                        onClick={() => handleOption("favoriteDecks")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>My Favorited Decks</h4>
                                    </div>
                                    {/* <div className={option === "collection"? "bigNoClass3 selected2 pointer account-option-item" : "bigNoClass3 pointer account-option-item half"}
                                        onClick={() => handleOption("collection")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>My Collection</h4>
                                    </div> */}
                                    {/* <div className={option === "wishList"? "bigFaith selected2 pointer account-option-item" : "bigFaith pointer account-option-item half"}
                                        onClick={() => handleOption("wishList")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>My Wishlist</h4>
                                    </div> */}
                                </span>
                                <span className="media-display hidden4">
                                    <div className={option === "profile"? "bigStaunch4 selected5 pointer account-option-item" : "bigStaunch4 pointer account-option-item half2"}
                                        onClick={() => handleOption("profile")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>Profile</h4>
                                    </div>
                                    <div className={option === "security"? "bigPower4 selected5 pointer account-option-item" : "bigPower4 pointer account-option-item half2"}
                                        onClick={() => handleOption("security")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>Security</h4>
                                    </div>
                                    <div className={option === "myDecks"? "bigUnity4 selected5 pointer account-option-item" : "bigUnity4 pointer account-option-item half2"}
                                        onClick={() => handleOption("myDecks")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>My Uploaded Decks</h4>
                                    </div>
                                    <div className={option === "favoriteDecks"? "bigCanny4 selected5 pointer account-option-item" : "bigCanny4 pointer account-option-item half2"}
                                        onClick={() => handleOption("favoriteDecks")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>My Favorited Decks</h4>
                                    </div>
                                    <div className={option === "collection"? "bigNoClass3 selected5 pointer account-option-item" : "bigNoClass3 pointer account-option-item half2"}
                                        onClick={() => handleOption("collection")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>My Collection</h4>
                                    </div>
                                    <div className={option === "wishList"? "bigFaith4 selected5 pointer account-option-item" : "bigFaith4 pointer account-option-item half2"}
                                        onClick={() => handleOption("wishList")}
                                    >
                                        <h4 style={{fontWeight: "600", margin: "12px"}}>My Wishlist</h4>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="account-option-container">
                            {/* <ImageUpload/> */}
                            <AccountDecks option={option}/>
                            <AccountFavoriteDecks option={option}/>
                        </div>
                    </div>
                </div>:
                <div className="textwindow">
                    <h1 className="undercontext">This Feature Is For Users Only</h1>
                    <h3 className="undercontext">Redirecting in 3 Seconds</h3>
                </div>
            }
        </div>
    );
}

export default FBAccountPage;
